console.log("TNTpayment debug mode enabled");

window.__tnt || (window.__tnt = {});
window.__tnt.subscription || (__tnt.subscription = {});
window.__tnt.subscription.a || (__tnt.subscription.a = []);
window.__tnt.subscription.d || (__tnt.subscription.d = []);

window.__tnt.subscription.a.push(function(oResp){
    console.log("DEBUG: ACESS CONTROL - Access Granted", oResp);
});

window.__tnt.subscription.d.push(function(oResp){
    console.log("DEBUG: ACESS CONTROL - Access Denied", oResp);
});


/*
 * Debug Tests For the Access Log goes in here
 */
 
/* 
 * Product Impression Finished
 *
 * @listens BLOXSubscriptionProductListViewed
 * @event dataLayer#productImpressionFinished 
 */
window.addEventListener('BLOXSubscriptionProductListViewed',function(ev){
    console.log("TNTpayment caught BLOXSubscriptionProductListViewed", ev);
});

/* 
 * Product Click
 *
 * @listens BLOXSubscriptionProductListViewed
 */
window.addEventListener('BLOXSubscriptionProductClicked', function(ev){
    console.log("TNTpayment caught BLOXSubscriptionProductClicked", ev);
});

/* 
 * Product Detail Viewed
 *
 * @listens BLOXSubscriptionProductListViewed
 */
window.addEventListener('BLOXSubscriptionProductViewed', function(ev){
    console.log("TNTpayment caught BLOXSubscriptionProductViewed", ev);
});

/*
 * Product Remove From Cart
 *
 * @listens BLOXSubscriptionProductRemoved
 */
window.addEventListener('BLOXSubscriptionProductRemoved', function(ev){
    console.log("TNTpayment caught BLOXSubscriptionProductRemoved", ev);
});

/*
 * Product Add to Cart
 *
 * @listens BLOXSubscriptionProductAdded
 */
window.addEventListener('BLOXSubscriptionProductAdded', function(ev){
    console.log("TNTpayment caught BLOXSubscriptionProductAdded", ev);
});

/*
 * Checkout Started
 *
 * @listens BLOXSubscriptionCheckoutStarted
 */
window.addEventListener('BLOXSubscriptionCheckoutStarted', function(ev){
    console.log("TNTpayment caught BLOXSubscriptionCheckoutStarted", ev);
});

/*
 * Checkout Step Viewed
 *
 * @listens BLOXSubscriptionCheckoutStepViewed
 */
window.addEventListener('BLOXSubscriptionCheckoutStepViewed', function(ev){
    console.log("TNTpayment caught BLOXSubscriptionCheckoutStepViewed: " + ev.detail.step, ev);
});



/*
 * Checkout Step Viewed
 *
 * @listens BLOXSubscriptionCheckoutStepCompleted
 */
window.addEventListener('BLOXSubscriptionCheckoutStepCompleted', function(ev){
    console.log("TNTpayment caught BLOXSubscriptionCheckoutStepCompleted: " + ev.detail.step, ev);
});

/*
 * Payment Info Entered
 *
 * @listens BLOXSubscriptionPaymentInfoEntered
 */
window.addEventListener('BLOXSubscriptionPaymentInfoEntered',function(ev){
    console.log("TNTpayment caught BLOXSubscriptionPaymentInfoEntered", ev);
});

/*
 * Order Completed
 *
 * @listens BLOXSubscriptionOrderComplete
 */
window.addEventListener('BLOXSubscriptionOrderCompleted', function(ev){
    console.log("TNTpayment caught BLOXSubscriptionOrderCompleted", ev);
});
